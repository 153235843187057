/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { useState, setState } from 'react';
/** @jsx jsx */
import { Button, Input, Label, Heading, jsx } from 'theme-ui';

import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { navigate } from 'gatsby';

const Rsvp = () => {
  // Attending one toggle
  const [showAllFields, setAttending] = useState(false);
  const toggleAllFields = () => {
    setAttending(!showAllFields);
  };

  // Plus one toggle
  const [showPlusOne, setShowPlusOne] = useState(false);
  const togglePlusOne = () => {
    setShowPlusOne(!showPlusOne);
  };

  const [phone, setPhone] = useState();

  // Initiate forms
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Transforms the form data from the React Hook Form output to a format Netlify can read
  const encode = (data) =>
    Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');

  // Handles the post process to Netlify so we can access their serverless functions
  const handlePost = (formData, event) => {
    fetch(`/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'rsvp-form', ...formData }),
    })
      .then((response) => {
        navigate('/success/');
        reset();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(formData);
    event.preventDefault();
  };

  return (
    <div
      sx={{
        variant: 'rsvp',
      }}
    >
      <h1>RSVP</h1>
      <form
        onSubmit={handleSubmit(handlePost)}
        name="rsvp-form"
        method="POST"
        action="/success/"
        data-netlify="true"
        netlify-honeypot="got-ya"
      >
        <input type="hidden" name="form-name" value="rsvp-form" />
        <input type="hidden" name="formId" value="rsvp-form" {...register('formID')} />

        {/* Main Guest First Name */}
        <div sx={{ variant: 'forms.group' }}>
          <input
            id="mainGuestFirstName"
            name="mainGuestFirstName"
            placeholder="First Name"
            {...register('mainGuestFirstName', { required: true })}
          />
          <Label htmlFor="mainGuestFirstName" sx={{ variant: 'forms.group.label' }}>
            First Name
          </Label>
          {errors.mainGuestFirstName && <span>Please enter a first name</span>}
        </div>

        {/* Main Guest Last Name */}
        <div sx={{ variant: 'forms.group' }}>
          <input
            id="mainGuestLastName"
            name="mainGuestLastName"
            placeholder="Last Name"
            {...register('mainGuestLastName', { required: true })}
          />
          <Label htmlFor="mainGuestLastName" sx={{ variant: 'forms.group.label' }}>
            Last Name
          </Label>
          {errors.mainGuestLastName && <span>Please enter a last name</span>}
        </div>

        {/* Main Guest Email */}
        <div sx={{ variant: 'forms.group' }}>
          <input
            id="mainGuestEmail"
            name="mainGuestEmail"
            type="email"
            placeholder="Email Address"
            {...register('mainGuestEmail', {
              required: true,
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                message: 'Please check the format of your email address',
              },
            })}
          />
          <Label htmlFor="mainGuestEmail" sx={{ variant: 'forms.group.label' }}>
            Email
          </Label>
          {errors.mainGuestEmail && <span>Please enter a valid email address</span>}
        </div>

        {/* Attending Selection */}
        <p>Will you be attending?</p>
        <div sx={{ variant: 'forms.radioGroup' }}>
          <label htmlFor="attendingSelectionYes" sx={{ variant: 'forms.radioGroup.label' }}>
            <input
              sx={{ variant: 'forms.radio' }}
              {...register('attendingSelection')}
              type="radio"
              name="attendingSelection"
              value="yes"
              id="attendingSelectionYes"
              onChange={() => setAttending(true)}
            />

            <span sx={{ variant: 'forms.radioGroup.label.inner' }}>I'm in!</span>
          </label>
          <label htmlFor="attendingSelectionNo" sx={{ variant: 'forms.radioGroup.label' }}>
            <input
              sx={{ variant: 'forms.radio' }}
              {...register('attendingSelection')}
              type="radio"
              name="attendingSelection"
              value="no"
              id="attendingSelectionNo"
              onChange={() => setAttending(false)}
            />
            <span sx={{ variant: 'forms.radioGroup.label.inner' }}>Can't make it</span>
          </label>
        </div>

        {showAllFields ? (
          <>
            {/* Phone Number */}
            <div sx={{ variant: 'forms.group' }}>
              <PhoneInput
                control={control}
                name="phoneNumber"
                value={phone}
                onChange={setPhone}
                defaultCountry="US"
                placeholder="Phone Number"
                rules={{ required: true, validate: isPossiblePhoneNumber }}
              />
              <Label htmlFor="phoneNumber" sx={{ variant: 'forms.group.label' }}>
                Phone Number
              </Label>
              {errors.phoneNumber && <span>Please enter a valid phone number</span>}
            </div>

            {/* Plus One */}
            <p>Do you need a +1?</p>
            <div sx={{ variant: 'forms.radioGroup' }}>
              <label htmlFor="plusOneSelectionYes" sx={{ variant: 'forms.radioGroup.label' }}>
                <input
                  sx={{ variant: 'forms.radio' }}
                  {...register('plusOneSelection')}
                  type="radio"
                  name="plusOneSelection"
                  value="yes"
                  id="plusOneSelectionYes"
                  onChange={() => setShowPlusOne(true)}
                />
                <span sx={{ variant: 'forms.radioGroup.label.inner' }}>Sure do!</span>
              </label>
              <label htmlFor="plusOneSelectionNo" sx={{ variant: 'forms.radioGroup.label' }}>
                <input
                  sx={{ variant: 'forms.radio' }}
                  {...register('plusOneSelection')}
                  type="radio"
                  name="plusOneSelection"
                  value="no"
                  id="plusOneSelectionNo"
                  onChange={() => setShowPlusOne(false)}
                />
                <span sx={{ variant: 'forms.radioGroup.label.inner' }}>Nope, just me</span>
              </label>
            </div>

            {showPlusOne ? (
              <div sx={{ variant: 'forms.group' }}>
                <input
                  id="plusOneName"
                  name="plusOneName"
                  placeholder="+1 Full Name"
                  {...register('plusOneName', { required: true })}
                />
                <Label htmlFor="plusOneName" sx={{ variant: 'forms.group.label' }}>
                  +1 Full Name
                </Label>
                {errors.plusOneName && <span>Please enter a name for a +1</span>}
              </div>
            ) : null}

            {/* Dietary Requirements */}
            <div sx={{ variant: 'forms.group' }}>
              <input
                id="dietaryRequirements"
                name="dietaryRequirements"
                placeholder="Dietary requirements"
                {...register('dietaryRequirements', { required: false })}
              />
              <Label htmlFor="dietaryRequirements" sx={{ variant: 'forms.group.label' }}>
                Dietary Requirements
              </Label>
              {errors.dietaryRequirements && <span>Please add any dietary requirements</span>}
            </div>

            {/* Have a song request? */}
            <div sx={{ variant: 'forms.group' }}>
              <input
                id="songRequest"
                name="songRequest"
                placeholder="Song Request"
                {...register('songRequest', { required: false })}
              />
              <Label htmlFor="songRequest" sx={{ variant: 'forms.group.label' }}>
                Song Request
              </Label>
              {errors.songRequest && <span>Please add a song request</span>}
            </div>
          </>
        ) : null}

        {/* Captcha */}
        <Label
          htmlFor="got-ya"
          style={{
            position: 'absolute',
            overflow: 'hidden',
            clip: 'rect(0 0 0 0)',
            height: '1px',
            width: '1px',
            margin: '-1px',
            padding: '0',
            border: '0',
          }}
        >
          Don’t fill this out if you're human:
          <input tabIndex="-1" name="got-ya" {...register('got-ya')} />
        </Label>

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default Rsvp;
