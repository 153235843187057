import React, { useState } from 'react';
import { SplitColorChannelText } from 'react-text-fun';

export const useMove = () => {
  const [state, setState] = useState({
    x: 542,
    y: 707,
  });

  const handleMouseMove = (e) => {
    e.persist();
    setState(() => ({ ...state, x: e.clientX, y: e.clientY }));
  };

  return {
    x: state.x,
    y: state.y,
    handleMouseMove,
  };
};

const HeroText = ({ text, fontSize, rotation, rgbOffset, addBlur, addNoise, fontFamily }) => (
  <SplitColorChannelText
    text={text}
    fontSize={fontSize}
    fontFamily={fontFamily}
    rotation={rotation}
    rgbOffset={rgbOffset}
    addBlur={addBlur}
    addNoise={addNoise}
    fill="white"
  />
);

export default HeroText;
