import { graphql, Link, StaticQuery } from 'gatsby';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import CTALink from './CTALink';

const navigationMenuQuery = graphql`
  query NavigationMenu {
    navigation: sanityNavigationMenu(title: { eq: "Main Menu" }) {
      items {
        title
        route
        link
        landingPageRoute {
          slug {
            current
          }
        }
      }
    }
  }
`;

const Navigation = () => (
  <StaticQuery
    query={navigationMenuQuery}
    render={(data) => {
      const navigationData = (data && data.navigation.items) || '';
      return (
        <ul sx={{ variant: 'navigation' }}>
          {navigationData.map((link) => (
            <li key={link.title.length * Math.random()}>
              <CTALink {...link} buttonActionClass="someClassName" />
            </li>
          ))}
        </ul>
      );
    }}
  />
);

export default Navigation;
