/** @jsx jsx */
import { Box, jsx, Link } from 'theme-ui';
// import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { Header } from '.';

const Layout = ({ children }) => (
  // const prezMatch = new RegExp('prez');
  // const isPrez = prezMatch.test(href.pathname);

  <Box>
    <Header />
    <main>{children}</main>
    <Box
      sx={{
        variant: 'layouts.footer',
      }}
    >
      <Box as="small">Rist Canyon Inn - Laporte, Colorado</Box>
      <Box as="small">08.13.2022 - 3pm</Box>
    </Box>
  </Box>
);
export default Layout;
