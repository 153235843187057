import '../fonts/fonts.css';
import nightOwl from '@theme-ui/prism/presets/night-owl';

import { keyframes } from '@emotion/react';
import layouts from './layouts';
import components from './components';
import { baselineMultiple, BASELINE, SPACE, FONT_SIZES, LINE_HEIGHTS } from './verticalRhythm';

const BREAKPOINTS = ['768px', '960px', '1200px', '1220px'];

const COLORS = {
  text: '#000',
  primary: '#191B1F',
  background: '#DBC5D7',
  secondary: '#786374',
  accent: '#495057',
  highlight: '#786374',
  muted: '#828291',
  white: '#ffffff',

  colorAlpha: 'rgba(255, 255, 255, 0)',
  color1: '#92f2f2',
  color2: '#d6cff1',
  color3: '#c4f2e5',
  color4: '#a8d0f0',
  color5: '#c2dcf2',
  color6: '#eed5f2',
  color7: '#9cb9fc',

  mode: {
    dark: {
      text: '#fff',
      background: '#000',
    },
  },
};

const keyframeGenerate = () => {
  let css = '';
  for (let i = 0; i <= 100; i++) {
    css = `${css}
  ${i}% { border-image: radial-gradient(at 0 ${((100 * i) / 100).toFixed(2)}%, ${COLORS.color1}, ${COLORS.color2}, ${
  COLORS.color3
    }, ${COLORS.color4}, ${COLORS.color5}, ${COLORS.color6}, ${COLORS.color7}) 2; }`;
  }

  return `${css}
	}`;
};

const rotate = keyframes`
    ${keyframeGenerate()}
  `;

const Heading = {
  fontFamily: 'heading',
};

const HeadingH1 = {
  ...Heading,
  fontSize: [3, 4, 5],
  lineHeight: [1, 2, 3],
  fontWeight: '300',
};

const HeadingH2 = {
  ...Heading,
  fontSize: [2, 3],
  lineHeight: [1],
  mb: 0,
  fontWeight: '450',

  '* + &': {
    mt: 2,
  },
};

const HeadingH3 = {
  fontSize: [1],
  lineHeight: [1],
  fontWeight: '900',

  '* + &': {
    mt: 2,
  },
};

const HeadingH4 = {
  fontSize: [0, 1],
  lineHeight: [0, 1],
  fontWeight: '700',

  '* + &': {
    mt: baselineMultiple(1.5),
  },
};

const HeadingH5 = {
  fontSize: [0, 1],
  fontWeight: 'body',
  textTransform: 'uppercase',

  '* + &': {
    mt: baselineMultiple(1.5),
  },
};

const HeadingH6 = {
  fontSize: [0],
  fontWeight: '700',

  '* + &': {
    mt: baselineMultiple(0.75),
  },
};

const RsvpType = {
  fontSize: '0.8rem',
  fontWeight: '425',
  fontFamily: 'fancyBody',
  color: 'accent',
};

export default {
  ...components,
  baseline: BASELINE,
  space: SPACE,

  fontSizes: FONT_SIZES,
  lineHeights: LINE_HEIGHTS,

  breakpoints: BREAKPOINTS,

  mediaQueries: {
    medium: `@media screen and (min-width: ${BREAKPOINTS[0]})`,
    large: `@media screen and (min-width: ${BREAKPOINTS[1]})`,
  },

  colors: COLORS,
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'RoslindaleVFDisplay, system-ui, sans-serif',
    monospace: 'Menlo, monospace',
    fancyBody: 'RoslindaleVF, system-ui, sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 330,
    bold: 700,
  },

  text: {
    hero: {
      width: '100%',
      height: '100%',
    },
    rsvp: {
      ...RsvpType,
    },
  },
  borderWidths: {
    0: '0',
    none: '0',
    sm: '2px',
    md: '4px',
    lg: '8px',
  },
  sizes: {
    mainBodyContainer: 500,
  },
  ...layouts,
  styles: {
    root: {
      '--angle': '70%',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 0,
      lineHeight: 0,
      a: {
        color: 'primary',
        '&:hover': {
          color: 'secondary',
        },
      },
      h1: {
        ...HeadingH1,
      },
      h2: {
        ...HeadingH2,
      },
      h3: {
        ...HeadingH3,
      },
      h4: {
        ...HeadingH4,
      },
      h5: {
        ...HeadingH5,
      },
      h6: {
        ...HeadingH6,
      },
      p: {
        mt: baselineMultiple(0.75),
      },

      strong: {
        fontWeight: 'bold',
      },

      em: {
        fontStyle: 'italic',
      },

      ul: {
        mt: baselineMultiple(0.75),
        px: [1],
        li: {
          listStyleType: 'square',
        },
      },
      ol: {
        mt: baselineMultiple(0.75),
        px: [1, 2],
        li: {
          listStyleType: 'number',
        },
      },
      li: {
        mt: baselineMultiple(0.5),
      },
      video: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
      },
      pre: {
        ...nightOwl,
      },
      figure: {
        m: 0,
        p: 0,
        width: '50vw',

        '& img': {
          borderRadius: '50% 50% 0 0',
          maxHeight: '75%',
        },
      },
    },

    SingleVideo: {
      width: '80%',
      borderRadius: '0.35em',
      boxShadow:
        '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07),0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
      '& video': {
        borderRadius: '0.35em',
      },
    },
    Slide: {
      textAlign: 'left',
      alignItems: 'start',
    },
  },
  forms: {
    group: {
      position: 'relative',
      padding: '15px 0 0',
      mt: [1],

      input: {
        width: '100%',
        border: 0,
        borderBottom: '1.25px dashed rgba(0, 0, 0, 0.25)',
        outline: 0,
        fontSize: '0.8rem',
        color: 'primary',
        padding: '0.5em 0',
        background: 'transparent',
        transition: 'border-color 0.2s',
        '&:focus': {
          paddingBottom: '6px',
          borderImageSlice: '10',
          animation: `10s ${rotate} linear infinite`,
          animationDirection: 'alternate',
        },
        '&::required, &::invalid': {
          boxShadow: 'none',
        },
      },
      'input::placeholder': {
        color: 'transparent',
      },
      'input:placeholder-shown': {
        '& ~ label': {
          fontSize: '0.8rem',
          cursor: 'text',
          top: '20px',
        },
      },
      'input:focus': {
        '~ label': {
          position: 'absolute',
          top: '0',
          display: 'block',
          transition: '0.2s',
          fontSize: '0.8rem',
          color: 'primary',
          fontWeight: '400',
        },
      },
      label: {
        display: 'block',
        position: 'absolute',
        top: 0,
        transition: '0.2s',
        fontSize: '0.8rem',
        color: 'accent',
        pointerEvents: 'none',
      },
      '& span': {
        fontFamily: 'body',
        color: 'secondary',
        mt: '1em',
        position: 'absolute',
        left: 0,
        bottom: '-23px',
      },
    },
    radio: {
      backgroundColor: 'red',
    },
    radioGroup: {
      margin: 'auto',
      display: 'flex',

      input: {
        opacity: 0,
        position: 'absolute',
      },
      label: {
        position: 'relative',
        display: 'inline-block',
        margin: '10px',
        fontSize: '0.75rem',

        inner: {
          position: 'relative',
          display: 'inline-block',
          paddingLeft: '40px',
          'input:focus ~ &': {
            '&:before': {
              paddingBottom: '6px',
              borderImageSlice: '10',
              animation: `10s ${rotate} linear infinite`,
              animationDirection: 'alternate',
            },
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            borderBottom: `1.25px dashed rgba(0, 0, 0, 0.25)`,
            width: '30px',
            transition: 'border-bottom 0.5s ease',
          },
          'input:checked ~ &': {
            '&:after': {
              content: "'\\2713'",
              color: 'primary',
              position: 'absolute',
              fontSize: '12px',
              fontWeight: 'bold',
              left: '12px',
              top: '1px',
            },
            '&:before': {
              paddingBottom: '6px',
              borderImageSlice: '10',
              animation: `10s ${rotate} linear infinite`,
              animationDirection: 'alternate',
            },
          },
        },
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: 'button',
      fontWeight: '450',
      fontSize: [0],
      color: 'primary',
      bg: 'transparent',
      padding: '1rem 2rem',
      border: `2px solid ${COLORS.primary}`,
      borderRadius: 0,
      cursor: 'pointer',
      mt: [1],
      transition: 'background-color 0.25s linear, color 0.25s linear',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'background',
        border: `2px solid ${COLORS.primary}`,
      },
    },
    secondary: {
      color: 'red',
      bg: 'green',
    },
    gray: {
      color: 'red',
      bg: 'green',
    },
  },
  rsvp: {
    ...RsvpType,
    margin: '0 auto 12vh auto',
    display: 'grid',
    maxWidth: '600px',
    justifyContent: 'center',
    minHeight: '100%',
    '& h1': {
      mb: 0,
      lineHeight: '1.2',
    },
    '& p': {
      mb: 0,
      pt: '15px',
      fontWeight: '545',
      letterSpacing: '0.07px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    m: '1rem 4%',
    backgroundColor: 'background',
    height: ['25px', '30px', '40px'],
  },
  navigation: {
    m: 0,
    p: 0,
    display: 'flex',
    flexFlow: 'row wrap',
    textTransform: 'uppercase',

    position: 'fixed',
    zIndex: '99999',
    li: {
      listStyleType: 'none',
      mt: 0,
      ml: ['1em', baselineMultiple(0.25), baselineMultiple(0.5)],
      '&:first-of-type': {
        ml: '0',
      },
      a: {
        textDecoration: 'none',
        fontWeight: '400',
        fontSize: ['0.75rem', 0],
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  logo: {
    m: 0,
    color: 'primary',
    height: ['25px', '30px', '40px'],
    width: 'auto',
    position: 'fixed',
    right: ['1rem', '2rem'],
    top: '1rem',
  },

  section: {
    hero: {
      // position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      '& h1': {
        fontSize: [4, 5],
        color: 'white',
        display: ['none', 'flex'],
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        zIndex: '100',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: ['translate(-50%, -70%)', 'translate(-50%, -83%)'],
        width: '100%',
        lineHeight: '2.25',
        wordBreak: 'break-word',
      },
      text: {
        fontFamily: 'heading',
        color: 'white',
        position: 'absolute',
        textAlign: 'center',
        zIndex: '50',
        width: '100%',
        margin: '0',
        top: '44%',
        transform: ['translateY(-45%)', 'translateY(-53%)'],
        opacity: '80%',
        display: ['none', 'block'],
      },
      image: {
        zIndex: '1',
        maxWidth: ['100vw', '85vw', '75vw'],
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: ['translate(-50%, -40%)', 'translate(-50%, -45%)', 'translate(-50%, -42%)'],
        width: ['100vw', '75vw', '60vw'],
      },
    },
    faq: {
      margin: '0 auto 12vh auto',
      display: 'grid',
      maxWidth: '600px',
      justifyContent: 'center',
    },
  },

  // fixedFooter: {
  //   display: 'block',

  //   '& div': {
  //     position: 'fixed',
  //     display: 'block',
  //     bottom: '10vh',
  //   },
  //   left: {
  //     left: '0',
  //     transform: 'rotate(-90deg)',
  //   },
  //   right: {
  //     right: '0',
  //     transform: 'rotate(90deg)',
  //   },
  // },
};
