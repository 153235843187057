import React from 'react';

const GraphQLErrorList = ({ graphQLErrors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {graphQLErrors.map((error) => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
);

export default GraphQLErrorList;
