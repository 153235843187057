// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Navigation, Logo } from '.';

const Header = () => (
  <div
    sx={{
      variant: 'header',
    }}
  >
    <Navigation />
    <Logo />
  </div>
);

export default Header;
