import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import clientConfig from '../../client-config';

export default ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const imageData = getGatsbyImageData(node.asset._id, { fit: 'FILLMAX', placeholder: 'BLURRED' }, clientConfig.sanity);
  return (
    <figure
      sx={{
        variant: 'section.hero.image',
      }}
    >
      <GatsbyImage loading="eager" image={imageData} alt={node.alt} className={node.class} />
      <figcaption
        sx={{
          variant: 'section.hero.text',
        }}
      >
        {node.caption}
      </figcaption>
    </figure>
  );
};
